<template>
  <transition name="expand-transition"
    @enter="enter"
    @after-enter="afterEnter"
    @leave="leave">
    <slot />
  </transition>
</template>

<script>
export default {
  name: 'ExpandTransition',
  components: {},
  mixins: [],
  props: {},
  data: () => {
    return {}
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  beforeDestroy() {},
  methods: {
    enter(element) {
      const width = getComputedStyle(element).width

      element.style.width = width
      element.style.position = 'absolute'
      element.style.visibility = 'hidden'
      element.style.height = 'auto'

      const height = getComputedStyle(element).height

      element.style.width = null
      element.style.position = null
      element.style.visibility = null
      element.style.height = 0

      // Force repaint to make sure the
      // animation is triggered correctly.
      // getComputedStyle(element).height

      // Trigger the animation.
      // We use `requestAnimationFrame` because we need
      // to make sure the browser has finished
      // painting after setting the `height`
      // to `0` in the line above.
      setTimeout(() => {
        requestAnimationFrame(() => {
          element.style.height = height
        })
      }, 20)
    },
    afterEnter(element) {
      element.style.height = 'auto'
    },
    leave(element) {
      const height = getComputedStyle(element).height

      element.style.height = height

      // Force repaint to make sure the
      // animation is triggered correctly.
      // getComputedStyle(element).height

      setTimeout(() => {
        requestAnimationFrame(() => {
          element.style.height = 0
        })
      }, 20)
    }
  },
  beforeRouteEnter (to, from, next) {},
  beforeRouteLeave (to, from, next) {}
}
</script>

<style lang="scss" scoped>
.expand-transition-enter-active,
.expand-transition-leave-active {
  transition: height 300ms ease-in-out;
  overflow: hidden;
}

.expand-transition-enter,
.expand-transition-leave-to {
  height: 0;
}
</style>
